.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .trigger:hover {
    color: #1890ff;
  }
  
  .logo {
    height: 32px;
    /* background: rgba(255, 255, 255, 0.2); */
    margin: 16px;
  }
  
/*.site-layout .site-layout-background {
  background: #fff;
  min-height: fit-content;
}*/

.site-layout .site-layout-background {
  background: #fff;
  max-height: 100vh;
  overflow: scroll;
}
